import React from 'react';
import { FiGithub } from 'react-icons/fi';
import { FaOrcid } from "react-icons/fa6";
import {
	FaMediumM, 
	FaLinkedinIn,
} from "react-icons/fa";
import { SiGooglescholar } from "react-icons/si";
import { HiAcademicCap } from "react-icons/hi2";
import AppFooterCopyright from './AppFooterCopyright';


const socialLinks = [
	{
		id: 1,
		name: 'github',
		icon: <FiGithub />,
		url: 'https://github.com/Jide-Muritala',
	},
	{
		id: 2,
		name: 'linkedin',
		icon: <FaLinkedinIn />,
		url: 'https://www.linkedin.com/in/jidemuritala/',
	},
	{
		id: 3,
		name: 'academia',
		icon: <HiAcademicCap />,
		url: 'https://www.urv.cat/en/research/support/programmes/marti-franques/cofund/fellows/babajide-muritala/',
	},
	{
		id: 4,
		name: 'medium',
		icon: <FaMediumM />,
		url: 'https://medium.com/@jidemuritala',
	},
	{
		id: 5,
		name: 'orcid',
		icon: <FaOrcid />,
		url: 'https://orcid.org/0000-0003-0443-4482',
	},
	{
		id: 5,
		name: 'google scholar',
		icon: <SiGooglescholar />,
		url: 'https://scholar.google.com/citations?hl=en&user=RWefj9wAAAAJ',
	},
];

const AppFooter = () => {
	return (
		<div className="container mx-auto">
			<div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
				{/* Footer social links */}
				<div className="font-general-regular flex flex-col justify-center items-center mb-12 sm:mb-27">
					<ul className="flex gap-4 sm:gap-8">
						{socialLinks.map((link) => (
							<a
							href={link.url}
							target="__blank"
							key={link.id}
							className="social-icon text-gray-400 hover:text-indigo-500 dark:hover:text-red-500 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-300"
						>
							<i className="text-xl sm:text-2xl md:text-3xl">
								{link.icon}
							</i>
						</a>
						))}
					</ul>
				</div>
				<AppFooterCopyright />
			</div>
		</div>
	);
};

export default AppFooter;

